<template>
  <div class="policy-detail">
    <!-- 产品信息 -->
    <div class="product-detail">
      <div class="message">
        <div class="name">{{detailData.productName}}</div>
        <div class="num">{{detailData.policyNo?detailData.policyNo:'-'}}</div>
        <div class="company">{{detailData.companyName}}</div>
        <!-- <div class="electronic-insurance">电子保单</div> -->
      </div>
      <div class="status">
        <img class="img" v-if="detailData.policyStatusName == '已退保'" src="@/assets/surrender.png" alt="">
        <img class='img' v-else-if="detailData.policyStatusName == '保障中'" src="@/assets/underwrite.png" alt="">
        <img class='img' v-else src="@/assets/efficacy.png" alt="">
      </div>
    </div>
    <!-- 投保人信息 -->
    <div class="applicant">
      <div class="title">投保人信息</div>
      <div class="item">
        <div class="name">姓名</div>
        <div class="msg">{{detailData.appliName?detailData.appliName:'-'}}</div>
      </div>
      <div class="item">
        <div class="name">证件类型</div>
        <div class="msg">身份证</div>
      </div>
      <div class="item">
        <div class="name">证件号码</div>
        <div class="msg">{{detailData.secAppliIdNumber?detailData.secAppliIdNumber:'-'}}</div>
      </div>
      <div class="item">
        <div class="name">手机号码</div>
        <div class="msg">{{detailData.secAppliMobile?detailData.secAppliMobile:'-'}}</div>
      </div>
      <div class="item">
        <div class="name">性别</div>
        <div class="msg">{{appliSex}}</div>
      </div>
    </div>
    <!-- 被保人信息 -->
    <div class="applicant">
      <div class="title">被保人信息</div>
      <div class="item">
        <div class="name">姓名</div>
        <div class="msg">{{detailData.insuredName}}</div>
      </div>
      <div class="item">
        <div class="name">证件类型</div>
        <div class="msg">身份证</div>
      </div>
      <div class="item">
        <div class="name">证件号码</div>
        <div class="msg">{{detailData.secInsuredIdNumber}}</div>
      </div>
      <div class="item">
        <div class="name">性别</div>
        <div class="msg">{{insuredSex}}</div>
      </div>
    </div>
    <!-- 保单信息 -->
    <div class="applicant">
      <div class="title">保单信息</div>
      <div class="item">
        <div class="name">产品名称</div>
        <div class="msg">{{detailData.productName}}</div>
      </div>
      <div class="item">
        <div class="name">保费</div>
        <div class="msg">{{detailData.price}}元</div>
      </div>
      <div class="item">
        <div class="name">保额</div>
        <div class="msg">{{detailData.amount}}万</div>
      </div>
      <div class="item">
        <div class="name">生效日期</div>
        <div class="msg">{{detailData.startTime}}</div>
      </div>
      <div class="item">
        <div class="name">终止日期</div>
        <div class="msg">{{detailData.endTime}}</div>
      </div>
    </div>
    <div class="btn-wrapper">
      <p class="download-btn" @click="downloadPolicy">下载电子保单</p>
      <p class="download-btn" @click="downloadInvoice" v-if="detailData.invoiceStatus == 1" style="margin-top:15px">下载发票</p>
      <p class="invoice-btn" @click="invoice(detailData.policyNo)" v-else-if="detailData.invoiceStatus == 0">开具发票</p>
    </div>
  </div>
</template>
<script>
import {getOrderDetail} from '@/utils/api.js'
export default {
  data() {
    return {
      detailData:{},
      appliSex:'', // 投保人性别
      insuredSex:'', // 被保人性别
    };
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 下载电子发票
    downloadPolicy(){
      if(this.detailData.policyUrl){ 
        // window.location.href = this.detailData.policyUrl;
        window.open(this.detailData.policyUrl);
      }
    },
    // 下载发票
    downloadInvoice(){
      if(this.detailData.invoiceUrl) {
        window.open(this.detailData.invoiceUrl);
      }
    },
    // 获取详情数据
    getData(){
      let parmas = {
        id:this.$route.query.id,
        openId: sessionStorage.getItem("openId")
      }
      // '121211212112121122121'
      
      getOrderDetail(parmas).then((res)=>{
        console.log(res)
        if(res.success){
          this.detailData = res.result;
          if(this.detailData.appliSex){
            if(this.detailData.appliSex == 1){
              this.appliSex = '男'
            } else {
              this.appliSex = '女'
            }
          } else {
            this.appliSex = '-'
          }
          if(this.detailData.insuredSex) {
            if(this.detailData.insuredSex == 1){
              this.insuredSex = '男'
            } else {
              this.insuredSex = '女'
            }
          } else {
            this.insuredSex = '-'
          }
          let data = {
            appliName:this.detailData.appliName,
            secAppliMobile:this.detailData.secAppliMobile, // 投保人脱敏手机号
            appliMobile:this.detailData.appliMobile, // 投保人手机号
          }
          sessionStorage.setItem('invoiceData',JSON.stringify(data));
        } else {
          this.$toast('详情获取失败');
        }
      })
    },
    // 开具发票
    invoice(policyNo){
      this.$router.push({
        path: "/invoice",
        query:{
          policyNo:policyNo
        }
      });
      
    }
  }
}
</script>

<style scoped lang="scss">
.policy-detail {
  height: 100%;
  min-height: calc(100vh);
  background: #f7f7f7;
  overflow-y: auto;
  padding-bottom: 40px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.policy-detail::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.product-detail {
  padding: 13px 15px 10px;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .message {
    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    .num {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      line-height: 19px;
      margin-top: 5px;
    }
    .company {
      font-size: 12px;
      font-weight: 400;
      color: #888888;
      line-height: 17px;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    .electronic-insurance {
      width: 71px;
      height: 27px;
      border-radius: 4px;
      border: 2px solid #888888;
      font-size: 13px;
      font-weight: 400;
      color: #888888;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .status {
    width: 80px;
    height: 63px;
    margin-top: 12px;
    .img {
      width: 80px;
      height: 63px;
    }
  }
}
.duty {
  .name {
    width: 160px !important;
  }
}
.applicant {
  padding: 24px 15px;
  margin-top: 8px;
  background: #ffffff;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .item {
    margin-top: 7px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      width: 110px;
    }
    .msg {
      width: 100%;
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: #888888;
      line-height: 23px;
      text-align: right;
    }
  }
}
.btn-wrapper {
  margin-top: 29px;
  p {
    margin: 0 auto;
    width: 325px;
    height: 45px;
    border-radius: 8px;
    text-align: center;
    line-height: 45px;
    font-weight: bold;
    font-size: 16px;
  }
  .download-btn {
    background: #ff7418;
    color: #ffffff;
  }
  .invoice-btn {
    color: #ff7418;
    border: 1px solid #ff7418;
    margin-top: 15px;
  }
}
</style>
